import { callApi } from "..";

export const apiGetListOrder = () =>
  callApi(
    `{
    merchantOrders {
      orders {
        id
        external_order_id
        order_number
        order_type
        created_at
        status
        grand_total
        payment_method
        notification_time
        notification_status
        flag_refund
        delivery_time
        shipping_method
        qty
        pickup_date
        pickup_time
        assign_from {
            stores_id
            name
            address
        }
        assign_reason
        shipper_info {
          id
        }
        vouchers_applied {
          code
          note
        }
      }
    }
  }`,
    null
  );

export const apiGetListOrderRefund = () =>
  callApi(
    `{
    merchantOrderHistory(flagRefund: 1) {
      orders {
        address
        created_at
        delivery_time
        firstname
        grand_total
        id
        lastname
        order_number
        payment_method
        qty
        restaurant_id
        shipping_method
        flag_refund
        status
        use_plastic
        shipper_info {
          first_name
          last_name
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }`,
    null
  );

export const apiMoveOrderToAnother = (data) =>
  callApi(
    `mutation ($orderId:Int!, $restaurantId:Int!, $reason:String!) {
    moveOrder(orderId:$orderId, restaurantId:$restaurantId, reason:$reason) {
      result
    }
  }`,
    data
  );

export const apiReciveOrder = (data) =>
  callApi(
    `mutation($id: Int!){
    received(id: $id){
      result
    }
  }`,
    data
  );

export const apiCookingOrder = (data) =>
  callApi(
    `mutation($id: Int!){
    cooking(id: $id){
      result
    }
  }`,
    data
  );

export const apiCancelOrder = (data) =>
  callApi(
    `mutation($orderId: Int!, $comment: String!){
    cancelOrder(orderId:$orderId, comment: $comment){
      result
    }
  }`,
    data
  );

export const apiGetAllStoreInTheArea = () =>
  callApi(
    `{
      getStoreJsonData {
        url
        version
      }
    }`,
    null
  );

export const apiAcceptShipping = (data) =>
  callApi(
    `mutation (
    $id: Int!
    $type: Int!
  ){
    merchantAcceptShipping (
      id:  $id
      type: $type
    ) {
      result
    }
  }`,
    data
  );

export const apiMerchantReadyToShip = (data) =>
  callApi(
    `mutation($id: Int!){
    merchantReadyToShip(id: $id){
      result
    }
  }`,
    data
  );

export const apiGetOrderStatusHistory = (data) =>
  callApi(
    `query($orderId: Int!){
    getOrderStatusHistory(
        orderId: $orderId
    ) {
        list {
            created_at
            status
            comment
        }
    }
}`,
    data
  );

export const apiGetDetailOrder = (data) =>
  callApi(
    `query($id: Int) {
    orderDetail(id:$id) {
      id
      order_number
      order_type
      created_at
      grand_total
      status
      pickup_date
      pickup_time
      flag_refund
      firstname
      lastname
      payment_method
      payment_method_code
      shipping_method
      shipping_amount
      use_plastic
      code_card
      ship_date
      note
      customer_phone
      delivery_time
      assign_from
      customer_rating
      customer_comment
      address
      address_note
      phone
      external_order_id
      reason_cancel
       voucher_code
        merchant_code
      items {
        name
        qty
        price
        pos_name
        options {
          name
          qty
          price
          pos_name
        }
      }
      discount {
         amount {
              currency
              value
          }
         label
      }
      vouchers_applied {
        id
        code
        amount
        note
      }
    }
  }`,
    data
  );
export const apiBomOrder = (data) =>
  callApi(
    `mutation($id:Int!) {
      bom(id:$id){
         result
       }
     }`,
    data
  );

export const apiCompleteOrder = (data) =>
  callApi(
    `mutation($id:Int!, $time:String!) {
      completeMerchant(id:$id, time:$time){
         result
       }
     }`,
    data
  );

export const apiShipping = (data) =>
  callApi(
    `mutation($id:Int!) {
      shipping(id:$id){
        result
      }
    }`,
    data
  );

export const apiApproveRefund = (data) =>
  callApi(
    `mutation($id:Int!) {
      approveRefund(orderId:$id){
        result
      }
    }`,
    data
  );

export const apiGetOrderHistory = () =>
  callApi(
    `query{
      merchantOrderHistory(currentPage: 1, pageSize: 20){
        orders {
            address
            created_at
            delivery_time
            firstname
            grand_total
            id
            lastname
            order_number
            order_type
            payment_method
            qty
            restaurant_id
            shipping_method
            status
            use_plastic
            shipper_info {
              first_name
              last_name
            }
          }
          page_info {
            current_page
            page_size
            total_pages
          }
      }
    }`
  );

export const apiGetReasonCanel = () =>
  callApi(
    `query {
      storeConfig {
          reasons_cancel
      }
    }`
  );

export const apiGetTurnOffAppSound = () =>
  callApi(
    `query{
      storeConfig {
          turn_off_app_sound
      }
    }`
  );

export const apiGetTimeReloadOrder = () =>
  callApi(
    `query{
      storeConfig {
        time_reload_order
      }
    }`
  );

export const apiNotRefund = (data) =>
  callApi(
    ` mutation ($id: Int!, $shipperId: Int!) {
      noRefund(orderId: $id, shipperId: $shipperId) {
        result
      }
    }`,
    data
  );

export const apiSetFlagRefund = (data) =>
  callApi(
    `mutation ($orderId: Int!) {
      setFlagRefundToWaitingApproved(orderId: $orderId) {
        result
      }
    }`,
    data
  );
